<template>
  <div>
    <b-form-group :label="label" :state="state">
      <template v-if="label" v-slot:label>
        {{ label }}
        <span v-if="required" class="text-danger"> (*) </span>
      </template>
      <v-select
        id="classObject"
        v-model="classObject"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="classObjects"
        :disabled="school == null || !enable"
        :placeholder="t('Lớp học')"
        @input="classChanged"
        @open="onOpen"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select
      >
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    school: {
      type: Object,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    availableFor: {
      type: String,
      default: null,
    },
    filterActive: {
      type: Boolean,
      default: null,
    },
    showStatus: {
      type: Boolean,
      default: null,
    },
    grade: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      classObject: this.defaultValue,
      classObjects: [],
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  watch: {
    school(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.classObject = null;
        this.classChanged();
        if (newVal) {
          this.fetchClasses();
        } else {
          this.classObjects = [];
        }
      }
    },
    grade(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.classObject = null;
        this.classChanged();
        if (newVal) {
          this.fetchClasses();
        } else {
          this.classObjects = [];
        }
      }
    },
  },
  methods: {
    classChanged() {
      this.$emit("input", this.classObject);
    },
    onOpen() {
      if (this.classObjects.length === 0) {
        this.fetchClasses();
      }
    },
    fetchClasses() {
      const filter = { schoolId: this.school.id };

      useJwt
        .getClasses({ filter, sort: { field: "name", type: "asc" } })
        .then((res) => {
          this.classObjects = !this.grade ? res.data.items : res.data.items.filter(item => this.extractNumber(item.name) === this.grade);
        });
    },
    onItemCreated(newItem) {
      this.classObject = newItem;
      this.classChanged();
    },
    extractNumber(str) {
      const match = str.match(/^\d+/);
      return match ? parseInt(match[0], 10) : null;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
.list-header {
  padding: $options-padding-y $options-padding-x;
  color: $secondary;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>
